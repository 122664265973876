<template>
  <div>
    <buildFieldsRows
      :templateContent="ldapHeader"
      v-model="ldapHeaderValue"
      languageIndex="ldapStep"
      @updated-fields="emitUpdateFields"
    ></buildFieldsRows>

    <configContainer
      v-if="ldapType == 'search'"
      :title="languagePack('testJSConfig', 'responsePacket')"
    >
      <template v-slot:content>
        <evalContentTable
          v-if="ldapType == 'search'"
          v-model="evalValue"
          operatorType
          :correctOperators="true"
          :result="result"
          isValid="isValid"
        ></evalContentTable>
        <onTimeOutNoResult
          onEvent="On Timeout"
          v-model="onTimeout"
          :result="result"
        ></onTimeOutNoResult>
        <onTimeOutNoResult
          v-if="ldapType == 'search'"
          onEvent="On No Result"
          v-model="onNoResult"
          :result="result"
        ></onTimeOutNoResult>
      </template>
    </configContainer>
    <div v-if="ldapType == 'add'">
      <v-divider></v-divider>
      <div class="headerTitleBlue mt-3">{{language.componentLabel.titleEntry}}*</div>
      <entryAdder
        v-model="entry"
        :addable="true"
        :headerTitle="true"
        indexName="Attribute"
        :result="result"
        :isValid="isValid"
      ></entryAdder>
    </div>
    <div v-if="ldapType == 'modify'">
      <v-divider></v-divider>
      <div class="headerTitleBlue mt-3 mb-3">{{language.componentLabel.titleModifications}}*</div>

      <associatedOption
        type="select"
        :fieldAttributes="{ options: modifyActions, name: 'Action' }"
        :fieldAttrInput="{ class: 'required' }"
        v-model="action"
        :templateContent="result"
      ></associatedOption>
      <entryAdder
        v-model="modify"
        :addable="true"
        :headerTitle="true"
        indexName="Attribute"
        :result="result"
        :isValid="isValid"
      ></entryAdder>
    </div>
    <onTimeOutNoResult
      v-if="ldapType != 'search'"
      onEvent="On Timeout"
      v-model="onTimeout"
      :result="result"
    ></onTimeOutNoResult>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import configContainer from "@/commonComponents/configContainer.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";

import evalContentTable from "@/components/legacy/evalContentTable.vue";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
import entryAdder from "@/components/legacy/entryAdder.vue";
import options from "@/cfg/options.json";

export default {
  data() {
    return {
      modifyActions: [
        ["add", "Add"],
        ["replace", "Replace"],
        ["delete", "Delete"],
      ],
    };
  },
  components: {
    associatedOption,
    onTimeOutNoResult,

    evalContentTable,
    configContainer,
    buildFieldsRows,
    entryAdder,
  },

  asyncComputed: {
    async stepInfo() {
      let stepInfo = {};
      let additionalStepsInfo = this.result.additional.additionalStepsInfo;
      if (
        typeof additionalStepsInfo != "undefined" &&
        typeof additionalStepsInfo["LDAP"] != "undefined"
      ) {
        stepInfo = additionalStepsInfo["LDAP"];

        return stepInfo;
      } else {
        let caller = this;
        return await new Promise((resolve) => {
          caller.frameworkAxiosRequest({
            method: 'post',
            url: 'serve.php?f=testing&f2=configureAndRunTests',
            data: {
              function: 'getLDAPClientServer',
              n_systemId: '',
            },
          })
              .then(function (post) {
              let stepInfo = post.data.result.json;

              resolve(stepInfo);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      }
    },
  },
  computed: {
    entry: {
      get() {
        return this.req.entry;
      },
      set(newVal) {
        this.updateRequestKey("entry", newVal);
      },
    },
    modify: {
      get() {
        return this.req.modify;
      },
      set(newVal) {
        this.updateRequestKey("modify", newVal);
      },
    },
    action: {
      get() {
        return this.req.action;
      },
      set(newVal) {
        this.updateRequestKey("action", newVal);
      },
    },
    ldapType: {
      get() {
        return this.req.type;
      },
      set(newVal) {
        //this.$set(this.req, "type", newVal);
        this.updateRequestKey("type", newVal);
      },
    },
    req() {
      let req = {};
      if (
        typeof this.stepStoredValue.req != "undefined" &&
        typeof this.stepStoredValue.req[0] != "undefined"
      ) {
        req = this.stepStoredValue.req[0];
      }
      return req;
    },

    connectionId: {
      get() {
        let connectionId = "";
        if (typeof this.stepStoredValue.parameters != "undefined") {
          connectionId = this.stepStoredValue.parameters.connectionId;
        }
        return connectionId;
      },
      set(newVal) {
        console.log("connection chaged");
        if (typeof this.stepStoredValue.parameters == "undefined") {
          this.$set(this.stepStoredValue, "parameters", {});
        }
        this.$set(this.stepStoredValue.parameters, "connectionId", newVal);
      },
    },
    ldapHeaderValue: {
      get() {
        let base = this.req.base;
        let rdn = this.req.rdn;
        let data = this.req.data;
        let filter = this.req.filter;
        let ldapHeaderValue = {
          connectionId: this.connectionId,
          base: base,
          type: this.ldapType,
          rdn: rdn,
          data: data,
          filter: filter,
        };
        return ldapHeaderValue;
      },
      set(newVal) {
        console.log(newVal);
        this.ldapType = newVal.type;
        this.connectionId = newVal.connectionId;
        if (this.ldapType == "search") {
          this.$set(this.req, "filter", newVal.filter);
        } else {
          this.$delete(this.req, "filter");
        }
        if (
          this.ldapType == "search" ||
          this.ldapType == "add" ||
          this.ldapType == "delete" ||
          this.ldapType == "modify"
        ) {
          this.$set(this.req, "base", newVal.base);
        } else {
          this.$delete(this.req, "base");
        }
        if (
          this.ldapType == "add" ||
          this.ldapType == "delete" ||
          this.ldapType == "modify"
        ) {
          this.$set(this.req, "rdn", newVal.rdn);
        } else {
          this.$delete(this.req, "rdn");
        }

        if (this.ldapType == "add_ldif" || this.ldapType == "delete_list") {
          this.$set(this.req, "data", newVal.data);
        } else {
          this.$delete(this.req, "data");
        }

        this.$emit("stored-value-changed", this.localStoredStep);
      },
    },
    ldapHeader() {
      let ldapHeader = {
        fields: {
          connectionId: {
            type: "select",
            fieldAttrInput: { class: "required" },
            addingURL:
              "serve.php?f=configuration&f2=outboundConnections&f3=nocsConnection",
            addingFunction: "addConnection",
          },
          base: {
            type: "select",
            fieldAttrInput: { class: "required" },
            inputFieldFormatter: "baseSelector",
            stepInfo: this.stepInfo,
          },
          type: {
            type: "select",
            options: options.ldapOperations,
            fieldAttrInput: { class: "required" },
          },
        },
        enableAdd: true,
        stepStoredValue: this.stepStoredValue,
        subType: this.stepStoredValue.subType,
        category: this.stepStoredValue.category,
        stepType: "LDAP",
        disableAllInputs: this.result.disableAllInputs,
        contentID: this.stepType,
      };
      if (
        !(
          this.ldapType == "search" ||
          this.ldapType == "add" ||
          this.ldapType == "delete" ||
          this.ldapType == "modify"
        )
      ) {
        this.$delete(ldapHeader.fields, "base");
      }
      if (
        this.ldapType == "add" ||
        this.ldapType == "delete" ||
        this.ldapType == "modify"
      ) {
        ldapHeader.fields.rdn = {
          type: "text",
        };
      }

      if (this.ldapType == "add_ldif" || this.ldapType == "delete_list") {
        ldapHeader.fields.data = {
          type: "textarea",
        };
      }
      if (this.ldapType == "search") {
        ldapHeader.fields.filter = {
          type: "text",
        };
      }
      //console.log(ldapHeader);
      if (this.stepInfo != null) {
        //ldapHeader.fields.connectionId.options = this.stepInfo.connectionsArray;
        //console.log(this.result);
        this.$set(
          ldapHeader.fields.connectionId,
          "associatedOption",
          this.stepInfo.connectionsArray
        );
        if (typeof ldapHeader.fields.base != "undefined") {
          //ldapHeader.fields.base.options = this.bases;
          this.$set(ldapHeader.fields.base, options, this.bases);
          // let server = this.stepInfo.clientServerInfo[this.connectionId]
          //   .serverId;
          // if (typeof this.stepInfo.LDAPServerBases[server] != "undefined") {
          //   let bases = this.stepInfo.LDAPServerBases[server];
          //   console.log(bases);
          //   ldapHeader.fields.base.options = JSON.parse(bases).bases;
          // } else {
          //   this.$set(
          //     ldapHeader.fields.base,
          //     "options",
          //     this.getLdapServerBases(server)
          //   );
          // }
        }
      }
      return ldapHeader;
    },
    localStoredStep: {
      get() {
        let localStoredStep = { req: {}, res: {} };
        if (typeof this.stepStoredValue != "undefined") {
          localStoredStep = this.stepStoredValue;
          if (typeof localStoredStep["req"] == "undefined") {
            this.$set(localStoredStep, "req", {});
          }
          if (typeof localStoredStep["res"] == "undefined") {
            this.$set(localStoredStep, "res", {});
          }
        }
        return localStoredStep;
      },
      set(newValue) {
        console.log("new local value set");
        this.$emit("stored-value-changed", newValue);
      },
    },

    headerEvalValue: {
      get() {
        let headerEvalValue = {};

        if (typeof this.evalValue["Header"] != "undefined") {
          headerEvalValue = this.evalValue["Header"];
        }

        return headerEvalValue;
      },
      set(newVal) {
        this.$set(this.evalValue, "Header", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    bodyEvalValue: {
      get() {
        let bodyEvalValue = {};

        if (typeof this.evalValue["Body"] != "undefined") {
          bodyEvalValue = this.evalValue["Body"];
        }

        return bodyEvalValue;
      },
      set(newVal) {
        console.log("new eval body set");
        this.$set(this.evalValue, "Body", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },

    evalValue: {
      get() {
        let evalValue = {};
        if (
          typeof this.localStoredStep["res"] != "undefined" &&
          typeof this.localStoredStep["res"]["eval"] != "undefined"
        ) {
          evalValue = this.localStoredStep["res"]["eval"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("eval", newVal);
      },
    },
    onTimeout: {
      get() {
        var onTimeout = {};

        if (
          typeof this.localStoredStep != "undefined" &&
          typeof this.localStoredStep["res"] != "undefined"
        ) {
          if (typeof this.localStoredStep.res.onTimeout != "undefined") {
            onTimeout = this.localStoredStep.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        if (typeof this.localStoredStep["res"] == "undefined") {
          this.$set(this.localStoredStep, "res", {});
        }
        this.$set(this.localStoredStep["res"], "onTimeout", newVal);
      },
    },
    onNoResult: {
      get() {
        var onNoResult = {};

        if (
          typeof this.localStoredStep != "undefined" &&
          typeof this.localStoredStep["res"] != "undefined"
        ) {
          if (typeof this.localStoredStep.res.onNoResult != "undefined") {
            onNoResult = this.localStoredStep.res.onNoResult;
          }
        }
        return onNoResult;
      },
      set(newVal) {
        if (typeof this.localStoredStep["res"] == "undefined") {
          this.$set(this.localStoredStep, "res", {});
        }
        this.$set(this.localStoredStep["res"], "onNoResult", newVal);
      },
    },
  },
  methods: {
    emitUpdateFields(info) {
      console.log("updated-fields emmited");
      this.$set(
        this.stepInfo,
        "connectionsArray",
        info.connectionId.associatedOption
      );
      this.connectionId = info.connectionId.value;
      //  this.$emit("updated-fields", info);
    },
    updateResponseKey(index, newVal) {
      // console.log("update parent");
      // let localStoredStep = { ...this.localStoredStep };

      this.$set(this.localStoredStep["res"], index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
    updateRequestKey(index, newVal) {
      console.log("update request");
      if (
        typeof this.localStoredStep["req"] == "undefined" ||
        !Array.isArray(this.localStoredStep["req"])
      ) {
        this.$set(this.localStoredStep, "req", []);
      }
      if (typeof this.localStoredStep["req"][0] == "undefined") {
        this.$set(this.localStoredStep.req, 0, {});
      }
      this.$set(this.localStoredStep["req"][0], index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
    updateKey(index, newVal) {
      this.$set(this.localStoredStep, index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
  },

  props: ["result", "stepStoredValue", "additionalStepsInfo","isValid"],
};
</script>
<style scoped>
.headerTitleBlue {
  font-family: inherit;
  color: #5c9ccc;
  font-size: 16px;
  font-weight: bold;
}
</style>